import { baseTheme } from '@ljagis/react-mui-themes';
import { createMuiTheme } from '@material-ui/core';
import { blueGrey, cyan } from '@material-ui/core/colors';

const theme = createMuiTheme(baseTheme, {
  palette: {
    primary: {
      main: cyan[700],
      light: '#56c8d8',
      dark: '#006978',
      contrastText: '#000000'
    },
    secondary: {
      main: blueGrey[800],
      light: '#62727b',
      dark: '#102027',
      contrastText: '#ffffff'
    }
  },
  typography: {
    button: {
      fontWeight: 400
    }
  }
});

export default theme;
