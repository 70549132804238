import { Layout1, Header, Sidebar, StackedTitle } from '@ljagis/react-core';
import React, { useEffect, useState } from 'react';
import {
  Box,
  Divider,
  IconButton,
  makeStyles,
  SvgIcon,
  Tooltip
} from '@material-ui/core';
import {
  emptyGeoJSONFeature,
  GeolocationButton,
  Map,
  MapOverlay,
  StyleGallery,
  useGeoJSONLayer,
  useMapNavigation,
  ZoomInButton,
  ZoomOutButton
} from '@ljagis/react-mapping';

import aerial from './assets/img/style-previews/satellite.png';
import aerial2 from './assets/img/style-previews/satellite@2x.png';
import streets from './assets/img/style-previews/streets.png';
import streets2 from './assets/img/style-previews/streets@2x.png';

import LocationIdentified from './components/LocationIdentified';
import LocationSearch from './components/LocationSearch';
import { ReactComponent as OpenSidebarIcon } from './icons/chrome_reader_mode_black_24dp.svg';
import { EsriResultData, MapLocation } from './models/Location';

const {
  PUBLIC_URL: publicFolder,
  REACT_APP_ESRI_SEARCH_URL: ESRI_SEARCH_URL
} = process.env;

const locationLayer = {
  type: 'circle',
  paint: {
    'circle-color': '#e91e63',
    'circle-radius': 6,
    'circle-stroke-color': '#ff6090',
    'circle-stroke-width': 4.5,
    'circle-stroke-opacity': 0.3
  }
};

const useStyles = makeStyles({
  map: {
    height: '100%',
    '& .mapboxgl-interactive': {
      cursor: 'pointer'
    }
  }
});

const mapStyles: {
  title: string;
  previewImage: string;
  previewImage2x: string;
  styleUrl: string;
}[] = [
  {
    title: 'Streets',
    previewImage: streets,
    previewImage2x: streets2,
    styleUrl: `${publicFolder}/mapstyles/outdoors.json`
  },
  {
    title: 'Aerial',
    previewImage: aerial,
    previewImage2x: aerial2,
    styleUrl: `${publicFolder}/mapstyles/nearmap.json`
  }
];

const App: React.FC = () => {
  const classes = useStyles();

  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [location, setLocation] = useState<MapLocation | undefined>();
  const [locationData, setLocationData] = useState<EsriResultData>();

  const { setData: setLocationGeoJSON } = useGeoJSONLayer({
    layers: [locationLayer]
  });

  const { flyTo } = useMapNavigation();

  useEffect(() => {
    if (!location) {
      setLocationData(undefined);
      setLocationGeoJSON(emptyGeoJSONFeature);
      return;
    }

    const fetchLocationResult = async () => {
      const result = await fetch(
        `${ESRI_SEARCH_URL}&geometry=${location.lng},${location.lat}`
      );
      const json = await result.json();
      const data: EsriResultData = json.results;

      setLocationData(data);
      setSidebarOpen(true);
    };

    fetchLocationResult();

    setLocationGeoJSON({
      type: 'Feature',
      properties: {},
      geometry: { type: 'Point', coordinates: [location.lng, location.lat] }
    });
  }, [location, setLocationGeoJSON]);

  return (
    <Layout1
      height="100vh"
      header={
        <Header color="secondary" minHeight={56}>
          <Box flex="1 1 auto">
            <StackedTitle title="City of Pasadena" subtitle="" />
          </Box>
          {!sidebarOpen && (
            <Tooltip title="Open Sidebar" enterDelay={1500}>
              <IconButton color="inherit" onClick={() => setSidebarOpen(true)}>
                <SvgIcon component={OpenSidebarIcon} />
              </IconButton>
            </Tooltip>
          )}
        </Header>
      }
      map={
        <Map
          className={classes.map}
          initialMapStyle={mapStyles[0].styleUrl}
          onMapClick={setLocation}
        />
      }
      mapOverlayTopLeft={[
        <MapOverlay key="locationSearch">
          <Box minWidth={280}>
            <LocationSearch maxZoom={17} />
          </Box>
        </MapOverlay>
      ]}
      mapOverlayTopRight={[
        <MapOverlay key="navigation">
          <Box>
            <ZoomInButton />
            <Divider />
            <ZoomOutButton />
          </Box>
        </MapOverlay>,
        <MapOverlay key="geolocation">
          <GeolocationButton />
        </MapOverlay>
      ]}
      sidebar={
        sidebarOpen ? (
          <Sidebar
            height={1}
            title="Location Information"
            onClose={() => setSidebarOpen(false)}
            content={
              <Box flex={1} display="flex" flexDirection="column" minHeight={0}>
                <LocationIdentified
                  flex={1}
                  location={location}
                  data={locationData}
                  onZoom={() => flyTo(location, 17)}
                  onClear={() => setLocation(undefined)}
                />
                <Divider />
                <StyleGallery items={mapStyles} />
              </Box>
            }
          />
        ) : undefined
      }
    />
  );
};

export default App;
