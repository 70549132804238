import { Box, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React from 'react';

const SuspectAreaDisplay: React.FC = () => {
  return (
    <Box py={1} px={2}>
      <Alert severity="warning" variant="outlined">
        <Typography component="span" display="block">
          This location is in a suspect area.
        </Typography>
        <Typography component="span" display="block">
          Results are not yet verified and additional research is suggested.
        </Typography>
      </Alert>
    </Box>
  );
};

export default SuspectAreaDisplay;
