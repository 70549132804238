export type MapLocation = { lng: number; lat: number };

export const VALUE_IN_FEET = 'Stretch.Pixel Value' as const;

export interface FloodplainsData {
  layerId: 0;
  layerName: 'Floodplains';
  attributes: {
    // Flood Zone
    FLD_ZONE: string;
    // Zone Subinterface
    ZONE_SUBTY: string;
  };
}

export interface Elevation100YrData {
  layerId: 7; // Added later than others. ArcGIS Pro doesn't change original orders.
  layerName: '100YR WSEL';
  attributes: {
    [VALUE_IN_FEET]: string | 'NoData';
  };
}

export interface Elevation500YrData {
  layerId: 1;
  layerName: '500YR WSEL';
  attributes: {
    [VALUE_IN_FEET]: string | 'NoData';
  };
}

export interface ElevationData {
  layerId: 2;
  layerName: 'Elevation';
  attributes: {
    [VALUE_IN_FEET]: string | 'NoData';
  };
}

export interface CityLimitsData {
  layerId: 3;
  layerName: 'City Limits';
  value: 'PASADENA' | 'PASADENA ETJ';
}

export interface SuspectAreasData {
  layerId: 4;
}

export interface ParcelsData {
  layerId: 5;
  layerName: 'Parcels';
  attributes: {
    AccountNumber: string;
    ImprovementValue: string | 'Null';
    LandValue: string | 'Null';
  };
}

export interface FirmPanelData {
  layerId: 6;
  layerName: 'Firm Panels';
  attributes: {
    FIRM_PAN: string;
  };
}

export type EsriResultData = (
  | FloodplainsData
  | Elevation100YrData
  | Elevation500YrData
  | ElevationData
  | CityLimitsData
  | SuspectAreasData
  | ParcelsData
  | FirmPanelData
)[];
