const layers = [
  {
    type: 'circle' as const,
    filter: [
      'any',
      ['match', ['geometry-type'], ['', 'Point'], true, false],
      ['match', ['geometry-type'], ['', 'MultiPoint'], true, false]
    ],
    paint: {
      'circle-color': 'rgba(0, 119, 182, 0.9)',
      'circle-radius': 6,
      'circle-stroke-color': 'rgba(255, 255, 255, 0.9)',
      'circle-stroke-width': 2
    }
  }
];

export default layers;
