import { Box } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React from 'react';

const InstructionsDisplay: React.FC = () => (
  <Box p={2}>
    <Alert severity="info" variant="outlined">
      Select a map location within the City of Pasadena for elevation and
      floodplain information.
    </Alert>
  </Box>
);

export default InstructionsDisplay;
