import { Box, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React from 'react';

const NotInBoundsDisplay: React.FC = () => {
  return (
    <Box p={2}>
      <Alert severity="warning" variant="outlined">
        <Typography component="span" display="block">
          Not in the City of Pasadena.
        </Typography>
        <Typography component="span" display="block">
          Select a location in the city for results.
        </Typography>
      </Alert>
    </Box>
  );
};

export default NotInBoundsDisplay;
