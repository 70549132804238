import { suggest, geocode } from '@esri/arcgis-rest-geocoding';
import { ApiKeyManager } from '@esri/arcgis-rest-request';
import { Box, InputAdornment, InputBase } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { Autocomplete } from '@material-ui/lab';
import {
  useGeoJSONLayer,
  useMapNavigation,
  emptyGeoJSONFeature
} from '@ljagis/react-mapping';
import React, { useState } from 'react';

import layers from './LocationSearch.layers';

const esriApiKey = process.env.REACT_APP_ESRI_API_KEY;
const esriAuthentication = ApiKeyManager.fromKey(esriApiKey || 'NOT_AVAIL');

export interface LocationSearchResult {
  text: string;
  magicKey: string;
}

interface LocationSearchProps {
  /** Maximum zoom when flying to results. */
  maxZoom?: number;
  /** Search input placeholder */
  placeholder?: string;
}

export const esriSuggest = async (
  query: string
): Promise<LocationSearchResult[]> => {
  const response = await suggest(query, {
    authentication: esriAuthentication,
    params: {
      location: '-95.1165, 29.6459',
      searchExtent: '-95.253486, 29.540665, -94.969557, 29.754601'
    }
  });

  return response.suggestions
    .filter(({ isCollection }) => !isCollection)
    .map(({ text, magicKey }) => ({
      text: text.replace(/, [A-Z]{2}, \d{5}, USA/, ''),
      magicKey
    }));
};

const LocationSearch: React.FC<LocationSearchProps> = ({
  maxZoom = 14,
  placeholder = 'Search for an address'
}) => {
  const { flyTo } = useMapNavigation();
  const {
    setData: setGeoJSONData,
    clearData: clearGeoJSONData
  } = useGeoJSONLayer({ layers });

  const [selected, setSelected] = useState<LocationSearchResult | null>(null);
  const [history, setHistory] = useState<LocationSearchResult[]>([]);
  const [results, setResults] = useState<LocationSearchResult[]>([]);

  const handleSelect = async (selected: LocationSearchResult) => {
    const response = await geocode({
      magicKey: selected.magicKey,
      authentication: esriAuthentication
    });

    if (!response.candidates[0]) return;

    const { x, y } = response.candidates[0].location;
    const coordinates = [x, y];

    setGeoJSONData({
      ...emptyGeoJSONFeature,
      geometry: { type: 'Point', coordinates }
    });

    flyTo(coordinates, maxZoom);

    setHistory((state) => {
      // Remove any previous records of current selection from history.
      // A new item will be added to the top of the history.
      // String match concatenated primary and secondary text.
      const selectedRemoved = state.filter(
        (historyItem) => historyItem.text !== selected.text
      );

      // Limit history to 10 items
      return [selected, ...selectedRemoved].slice(0, 10);
    });
  };

  return (
    <Autocomplete
      fullWidth
      getOptionLabel={({ text }) => text}
      filterOptions={(options) => options} // Default filter eliminates intersections. Filter to return all.
      options={results.length ? results : history}
      value={selected}
      clearOnBlur={false}
      onChange={(_, value: LocationSearchResult | null) => {
        value && handleSelect(value);
      }}
      onInputChange={async (_, value, reason) => {
        if (reason === 'reset' || !value.length) {
          setSelected(null);
          setResults([]);
          clearGeoJSONData();
          return;
        }

        if (reason === 'input') {
          // Only updated on user input
          // Input also changes when a selection is made. That will not set the input value.
          clearGeoJSONData();
          try {
            const results = await esriSuggest(value);
            setResults(results);
          } catch (_err) {
            // TODO. Error while searching. Show snackbar to inform the user.
          }
        }
      }}
      renderInput={(params) => (
        <div ref={params.InputProps.ref}>
          <Box py={0.5} px={1}>
            <InputBase
              placeholder={placeholder}
              fullWidth
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon color="action" />
                </InputAdornment>
              }
              inputProps={{
                style: { paddingTop: 8 }
              }}
              style={{ display: 'flex' }}
              {...params.inputProps}
            />
          </Box>
        </div>
      )}
    />
  );
};

export default LocationSearch;
