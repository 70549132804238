import React from 'react';
import ReactDOM from 'react-dom';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { MapboxProvider, MapProvider } from '@ljagis/react-mapping';

import App from './App';
import theme from './theme';

const { REACT_APP_MAPBOX_ACCESS_TOKEN: MAPBOX_ACCESS_TOKEN } = process.env;

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <MapboxProvider accessToken={MAPBOX_ACCESS_TOKEN || ''}>
        <MapProvider>
          <CssBaseline />
          <App />
        </MapProvider>
      </MapboxProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
