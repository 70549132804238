import React, { useState } from 'react';
import {
  Box,
  BoxProps,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Snackbar,
  SvgIcon,
  Toolbar,
  Tooltip
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/CancelOutlined';
import ZoomToIcon from '@material-ui/icons/CenterFocusStrongOutlined';
import { ReactComponent as FileCopy } from '../icons/content_copy-24px.svg';
import {
  CityLimitsData,
  Elevation100YrData,
  Elevation500YrData,
  ElevationData,
  EsriResultData,
  FirmPanelData,
  FloodplainsData,
  MapLocation,
  ParcelsData,
  SuspectAreasData,
  VALUE_IN_FEET
} from '../models/Location';
import InstructionsDisplay from './InstructionsDisplay';
import NotInBoundsDisplay from './NotInBoundsDisplay';
import SuspectAreaDisplay from './SuspectAreaDisplay';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    flex: '0 0 auto',
    background: theme.palette.grey[100]
  },
  toolbarButton: {
    padding: theme.spacing(1, 1, 1, 1.5)
  }
}));

const useSnackbarStyles = makeStyles((theme) => ({
  anchorOriginBottomRight: {
    maxWidth: 320 - theme.spacing(4),
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  }
}));

const DisplayItem: React.FC<{
  displayKind: string;
  result: number | string;
  onCopy?: () => void;
}> = ({ displayKind, result, onCopy }) => {
  const resultDisplay =
    typeof result === 'number' ? `${result.toFixed(2)} Feet` : result;

  return (
    <React.Fragment>
      <ListItem>
        <ListItemText secondary={displayKind} primary={resultDisplay} />
        {onCopy && (
          <ListItemSecondaryAction>
            <Tooltip title="Copy" enterDelay={1500}>
              <IconButton
                edge="end"
                aria-label="Copy to clipboard"
                onClick={() => {
                  navigator.clipboard.writeText(result.toString());
                  onCopy();
                }}
              >
                <SvgIcon component={FileCopy} style={{ fontSize: 16 }} />
              </IconButton>
            </Tooltip>
          </ListItemSecondaryAction>
        )}
      </ListItem>
    </React.Fragment>
  );
};
interface LocationIdentifiedProps extends BoxProps {
  location?: MapLocation;
  data?: EsriResultData;
  onZoom: () => void;
  onClear: () => void;
}

const LocationIdentified: React.FC<LocationIdentifiedProps> = ({
  location,
  data,
  onZoom,
  onClear,
  ...rootProps
}) => {
  const classes = useStyles();
  const snackbarClasses = useSnackbarStyles();

  const [copySnackbar, setCopySnackbar] = useState<string>();

  if (!(location && data))
    return (
      <Box {...rootProps}>
        <InstructionsDisplay />
      </Box>
    );

  // All layer result filter based on layerId/layer order
  // layerId 3 is city limits polygon
  const cityLimitsData = data.find((d) => d.layerId === 3) as
    | CityLimitsData
    | undefined;

  if (!cityLimitsData)
    return (
      <Box {...rootProps}>
        <NotInBoundsDisplay />
      </Box>
    );

  let floodPlain: string | null = null;
  let elevation: number | null = null;
  let elevation100Yr: number | null = null;
  let elevation500Yr: number | null = null;
  let firmPanel: string | null = null;
  let depth: number | null = null;
  let isCity = true;
  let hcadNumber: string | null = null;
  let hcadImprovementValue: string | null = null;
  let hcadLandValue: string | null = null;
  let isSuspectArea = false;

  if (cityLimitsData) {
    const floodPlainData = data.find((d) => d.layerId === 0) as
      | FloodplainsData
      | undefined;

    const elevation100YrData = data.find((d) => d.layerId === 7) as
      | Elevation100YrData
      | undefined;

    const elevation500YrData = data.find((d) => d.layerId === 1) as
      | Elevation500YrData
      | undefined;

    const elevationData = data.find((d) => d.layerId === 2) as
      | ElevationData
      | undefined;

    const suspectAreasData = data.find((d) => d.layerId === 4) as
      | SuspectAreasData
      | undefined;

    const parcelsData = data.find((d) => d.layerId === 5) as
      | ParcelsData
      | undefined;

    const firmPanelData = data.find((d) => d.layerId === 6) as
      | FirmPanelData
      | undefined;

    isCity = cityLimitsData.value === 'PASADENA';

    if (floodPlainData) {
      const floodZone = floodPlainData.attributes.FLD_ZONE;
      const zoneSubtype = floodPlainData.attributes.ZONE_SUBTY.trim().length
        ? ` (${floodPlainData.attributes.ZONE_SUBTY})`
        : '';
      floodPlain = floodZone + zoneSubtype;
    } else {
      floodPlain = 'Outside 500-Year Floodplain';
    }

    if (elevationData) {
      const elevationString = elevationData.attributes[VALUE_IN_FEET];
      const elevationStringParsed = parseFloat(elevationString);
      elevation = !Number.isNaN(elevationStringParsed)
        ? elevationStringParsed
        : null;
    }

    if (elevation100YrData) {
      const elevation100YrString = elevation100YrData.attributes[VALUE_IN_FEET];
      const elevation100YrStringParsed = parseFloat(elevation100YrString);
      elevation100Yr = !Number.isNaN(elevation100YrStringParsed)
        ? elevation100YrStringParsed
        : null;
    }

    if (elevation500YrData) {
      const elevation500YrString = elevation500YrData.attributes[VALUE_IN_FEET];
      const elevation500YrStringParsed = parseFloat(elevation500YrString);
      elevation500Yr = !Number.isNaN(elevation500YrStringParsed)
        ? elevation500YrStringParsed
        : null;
    }

    if (elevation500Yr && elevation) {
      depth = elevation500Yr - elevation;
    }

    if (firmPanelData) {
      firmPanel = firmPanelData.attributes.FIRM_PAN;
    }

    if (suspectAreasData) {
      isSuspectArea = true;
    }

    if (parcelsData) {
      hcadNumber = parcelsData.attributes.AccountNumber;
      hcadImprovementValue =
        parcelsData.attributes.ImprovementValue === 'Null'
          ? 'No Data'
          : parseInt(
              parcelsData.attributes.ImprovementValue,
              10
            ).toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 0
            });
      hcadLandValue =
        parcelsData.attributes.LandValue === 'Null'
          ? 'No Data'
          : parseInt(parcelsData.attributes.LandValue, 10).toLocaleString(
              'en-US',
              {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0
              }
            );
    }
  }

  return (
    <Box {...rootProps} display="flex" flexDirection="column" overflow="hidden">
      <Toolbar className={classes.toolbar} disableGutters variant="dense">
        <Box flex={1} />
        <Box px={1}>
          <Button
            className={classes.toolbarButton}
            color="primary"
            startIcon={<ZoomToIcon />}
            onClick={onZoom}
          >
            Zoom To
          </Button>
          <Button
            className={classes.toolbarButton}
            color="primary"
            startIcon={<ClearIcon />}
            onClick={onClear}
          >
            Clear
          </Button>
        </Box>
      </Toolbar>
      <Box flex={1} overflow="hidden auto">
        <List dense>
          {isSuspectArea && <SuspectAreaDisplay />}
          <DisplayItem
            displayKind="City or ETJ"
            result={isCity ? 'City' : 'ETJ'}
          />
          <DisplayItem
            displayKind="Location"
            result={`${location.lat.toFixed(5)}, ${location.lng.toFixed(5)}`}
            onCopy={() => setCopySnackbar('Location copied to clipboard')}
          />
          {floodPlain && (
            <DisplayItem
              displayKind="Flood Zone"
              result={floodPlain}
              onCopy={() => setCopySnackbar('Flood Zone copied to clipboard')}
            />
          )}
          {firmPanel && (
            <DisplayItem
              displayKind="FIRM Panel"
              result={firmPanel}
              onCopy={() => setCopySnackbar('FIRM Panel copied to clipboard')}
            />
          )}
          {elevation && (
            <DisplayItem
              displayKind="Elevation"
              result={elevation}
              onCopy={() => setCopySnackbar('Elevation copied to clipboard')}
            />
          )}
          {elevation100Yr && (
            <DisplayItem
              displayKind="100-year Water Surface Elevation"
              result={elevation100Yr}
              onCopy={() =>
                setCopySnackbar(
                  '100-year Water Surface Elevation copied to clipboard'
                )
              }
            />
          )}
          {elevation500Yr && (
            <DisplayItem
              displayKind="500-year Water Surface Elevation"
              result={elevation500Yr}
              onCopy={() =>
                setCopySnackbar(
                  '500-year Water Surface Elevation copied to clipboard'
                )
              }
            />
          )}
          {depth && (
            <DisplayItem
              displayKind="Depth"
              result={depth}
              onCopy={() => setCopySnackbar('Depth copied to clipboard')}
            />
          )}
          {hcadNumber && (
            <Box my={1}>
              <Divider />
            </Box>
          )}
          {hcadNumber && (
            <DisplayItem
              displayKind="HCAD Number"
              result={hcadNumber}
              onCopy={() => setCopySnackbar('HCAD Number copied to clipboard')}
            />
          )}
          {hcadImprovementValue && (
            <DisplayItem
              displayKind="Improvement Value"
              result={hcadImprovementValue}
              onCopy={() =>
                setCopySnackbar('Improvement Value copied to clipboard')
              }
            />
          )}
          {hcadLandValue && (
            <DisplayItem
              displayKind="Land Value"
              result={hcadLandValue}
              onCopy={() => setCopySnackbar('Land Value copied to clipboard')}
            />
          )}
        </List>
      </Box>

      <Snackbar
        classes={snackbarClasses}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        open={!!copySnackbar}
        autoHideDuration={6000}
        onClose={(_e, reason) => {
          if (reason === 'clickaway') return;
          setCopySnackbar(undefined);
        }}
        message={copySnackbar}
      />
    </Box>
  );
};

export default LocationIdentified;
